import React from "react";

export default function DividerPointedTop(props) {
  var style = "w-screen -py-1 ";
  style += props.background;
  return (
    <svg
      viewBox="0 0 1440 74"
      className={style}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m0 1317 369-74 1071 74z"
        fill={props.fill}
        fill-rule="evenodd"
        transform="translate(0 -1243)"
      />
    </svg>
  );
}
