import React from "react";
import Tablet from "../device/tablet";
import DividerPointedTop from "../divider/divider-pointed-top";
import Title from "../text/title";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import poster from "../../images/screenshot/cork-midsummer/artgifts.webp";
import videoMp4 from "../../assets/cork-midsummer/cork-midsummer.mp4";
import videoWebm from "../../assets/cork-midsummer/cork-midsummer.webm";

export default function RecentClient(props) {
  return (
    <section id="recent-client " className="relative bg-primaryCorkMidsummer lg:pb-10">
      <svg class="absolute h-full w-full inset-0" xmlns="http://www.w3.org/2000/svg">
        <circle cx="0%" cy="50%" r="300" stroke="#fefefe1a" stroke-width="10" fill="none"/>
        <circle cx="80%" cy="50%" r="500" stroke="#fefefe1a" stroke-width="10" fill="none"/>
        <circle cx="100%" cy="50%" r="400" stroke="#fefefe1a" stroke-width="10" fill="none"/>
    </svg>
      <DividerPointedTop fill={"#EE2A34"} background={"bg-background1"} />
      <p className="w-full font-main text-center text-white p-10">
        Recent Client
      </p>
      <hr className="w-20 h-10 mx-auto my-4" />

      <Title
        textStyle={"font-title text-5xl md:text-7xl lg:text-9xl text-center mx-auto w-full md:w-2/3"}
        containerStyle={" text-white"}
        text="Cork Midsummer Festival"
      />

      <div className="flex flex-col-reverse lg:flex-row lg:justify-evenly mx-auto max-w-7xl">

        <p className="font-main m-auto text-white text-2xl p-10 max-w-md leading-loose">
          Originally imagined in Helskini, we partnered with the{" "}
          <OutboundLink
            href="https://www.corkmidsummer.com/"
            target="_blank"
            rel="noreferrer"
            className="font-semibold">
            Cork Midsummer Festival
          </OutboundLink>{" "}
          to launch{" "}
          <OutboundLink
            href="https://artgifts.ie"
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-white">
            artgifts.ie
          </OutboundLink>
          , delivering bespoke performances to the doorsteps of Cork City
          residents during the festival.
        </p>

        <div className="transform rotate-4 -skew-y-1 my-10 mx-auto p-4">
          <Tablet
            id={"cork-midsummer-video"}
            poster={poster}
            mp4={videoMp4}
            webm={videoWebm}
          />
        </div>
      </div>
    </section>
  );
}
