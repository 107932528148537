import React from "react";

export default function Tablet(props) {
  var style =
    "mx-auto lg:w-full shadow-xl p-1 lg:p-2 rounded-2xl lg:rounded-3xl lg:backdrop-blur-lg "

    if (props.tilt) {
        style += "transform rotate-12 -skew-y-3"
    }

  return (
    <div className={style}>
      <video
        class="object-fill rounded-2xl"
        poster={props.poster}
        autoPlay
        muted
        defaultMuted
        playsinLine
        preload="auto"
        loop>
        <source src={props.webm} type="video/webm" />
        <source src={props.mp4} type="video/mp4" />
      </video>
    </div>
  );
}
